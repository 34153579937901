import React, { useState, useEffect, ChangeEvent } from "react";
import {
  ChakraProvider,
  Box,
  Flex,
  IconButton,
  Select,
  Text,
  Heading,
  Avatar,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { httpEuro } from "../services/euro/http";

interface Jogo {
  dataJogo: string;
  horaJogo: string;
  timeA: {
    nomeTime: string;
    imagemUrl: string;
  };
  golsTimeA: number;
  timeB: {
    nomeTime: string;
    imagemUrl: string;
  };
  golsTimeB: number;
}

const TabelaJogos: React.FC = () => {
  const [rodadaSelecionada, setRodadaSelecionada] = useState<number>(1);
  const [quantidadeRodadas, setQuantidadeRodadas] = useState<
    number | undefined
  >(undefined);
  const [jogosRodada, setJogosRodada] = useState<Jogo[]>([]);

  useEffect(() => {
    const fetchQuantidadeRodadas = async () => {
      try {
        const response = await httpEuro.get(`/euro/api/jogos/listar-rodada/1`);
        const data = response.data;
        setQuantidadeRodadas(data.conteudo.length);
      } catch (error) {
        console.error("Erro ao buscar a quantidade de rodadas:", error);
      }
    };

    fetchQuantidadeRodadas();
  }, []);

  useEffect(() => {
    const fetchJogosRodada = async () => {
      try {
        const response = await httpEuro.get(
          `/euro/api/jogos/listar-rodada/${rodadaSelecionada}`
        );
        const data = response.data;
        setJogosRodada(data.conteudo);
      } catch (error) {
        console.error("Erro ao buscar os jogos da rodada:", error);
      }
    };

    fetchJogosRodada();
  }, [rodadaSelecionada]);

  const proximaRodada = () => {
    setRodadaSelecionada((prev) => Math.min(prev + 1, 9));
  };

  const rodadaAnterior = () => {
    setRodadaSelecionada((prev) => Math.max(prev - 1, 1));
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setRodadaSelecionada(parseInt(e.target.value));
  };

  const getColorFromTeamName = (
    teamName: string
  ): { color: string; shadow: string } => {
    const lowerCaseTeamName = teamName.toLowerCase();
    if (lowerCaseTeamName.includes("amarelo")) {
      return { color: "#FFFF00", shadow: "none" };
    } else if (lowerCaseTeamName.includes("verde")) {
      return { color: "#008000", shadow: "none" };
    } else if (lowerCaseTeamName.includes("branco")) {
      return { color: "#FFFFFF", shadow: "0 0 2px 1px rgba(0, 0, 0, 0.5)" };
    } else if (lowerCaseTeamName.includes("vermelho")) {
      return { color: "#FF0000", shadow: "none" };
    } else if (lowerCaseTeamName.includes("azul")) {
      return { color: "#0000FF", shadow: "none" };
    } else {
      return { color: "#000000", shadow: "none" };
    }
  };

  return (
    <ChakraProvider>
      <Flex
        flex="1"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding={4}
      >
        <Box
          width={{ base: "90%", md: "80%", lg: "40%" }}
          maxHeight="calc(100vh - 14rem)"
          overflowY="auto"
        >
          <Flex justify="space-between" align="center" mb={4}>
            <IconButton
              aria-label="Anterior"
              icon={<ChevronLeftIcon />}
              onClick={rodadaAnterior}
              variant="ghost"
              colorScheme="green"
              size="lg"
            />
            <Select
              textAlign="center"
              value={rodadaSelecionada}
              onChange={handleSelectChange}
              fontWeight="bold"
            >
              {Array.from({ length: 6 }, (_, index) => (
                <option key={index + 1} value={index + 1}>
                  {index === 5 ? "Final" : `Rodada ${index + 1}`}
                </option>
              ))}
            </Select>

            <IconButton
              aria-label="Próxima"
              icon={<ChevronRightIcon />}
              onClick={proximaRodada}
              variant="ghost"
              colorScheme="green"
              size="lg"
            />
          </Flex>
          {jogosRodada.map((jogo, index) => (
            <Box
              key={index}
              bg="white"
              p={7}
              mb={4}
              borderRadius="md"
              boxShadow="md"
              textAlign="center"
            >
              <Heading size={["md"]} mb="5">
                {jogo.dataJogo} - {jogo.horaJogo}hs
              </Heading>
              <Flex align="center" justifyContent="center">
                <Avatar
                  fontWeight="bold"
                  color="black"
                  src={jogo.timeA && jogo.timeA.imagemUrl}
                  name={jogo.timeA?.nomeTime}
                  size={{ base: "lg", md: "xl" }}
                  overflow="hidden"
                  mr={[11, 2, 4, 3, 30, 100]}
                  objectFit="cover"
                  bg={getColorFromTeamName(jogo.timeA?.nomeTime || "").color}
                  boxShadow={
                    getColorFromTeamName(jogo.timeA?.nomeTime || "").shadow
                  }
                />

                <Heading size={["xl", "2xl"]} mx={2}>
                  {jogo.golsTimeA} x {jogo.golsTimeB}
                </Heading>
                <Avatar
                  fontWeight="bold"
                  color="black"
                  src={jogo.timeB && jogo.timeB.imagemUrl}
                  name={jogo.timeB?.nomeTime}
                  size={{ base: "lg", md: "xl" }}
                  overflow="hidden"
                  ml={[11, 2, 4, 3, 30, 100]}
                  objectFit="cover"
                  bg={getColorFromTeamName(jogo.timeB?.nomeTime || "").color}
                  boxShadow={
                    getColorFromTeamName(jogo.timeB?.nomeTime || "").shadow
                  }
                />
              </Flex>
            </Box>
          ))}
        </Box>
      </Flex>
    </ChakraProvider>
  );
};

export default TabelaJogos;
