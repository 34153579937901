import React, { useState, useEffect, useRef } from "react";
import {
  ChakraProvider,
  Box,
  Flex,
  Button,
  Select,
  Text,
  Heading,
  Avatar,
  MenuItem,
  Menu,
  MenuButton,
  MenuList,
  extendTheme,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FaFutbol, FaRegCircle, FaSquare } from "react-icons/fa";
import { httpEuro } from "../services/euro/http";

interface Atleta {
  id: number;
  nomeAtleta: string;
}

interface Jogo {
  id: number;
  idTimeA: number;
  idTimeB: number;
  timeA: { id: number; nomeTime: string; imagem: string | null };
  timeB: { id: number; nomeTime: string; imagem: string | null };
  golsTimeA: number;
  golsTimeB: number;
  dataJogo: string;
  horaJogo: string;
  situacaoJogo: { idSituacaoJogo: number };
}

interface Evento {
  idTime: "A" | "B";
  idAtleta: number;
  tipoEvento: "gol" | "amarelo" | "vermelho";
  nomeAtleta: string;
}

const theme = extendTheme({
  colors: {
    darkGreen: {
      500: "#004d40",
    },
  },
});

function Mesa() {
  const [rodadaSelecionada, setRodadaSelecionada] = useState<string>("");
  const [jogosRodada, setJogosRodada] = useState<Jogo[]>([]);
  const [jogoSelecionado, setJogoSelecionado] = useState<number | null>(null);
  const [timeSelecionado, setTimeSelecionado] = useState<string>("");
  const [atletasTimeA, setAtletasTimeA] = useState<Atleta[]>([]);
  const [atletasTimeB, setAtletasTimeB] = useState<Atleta[]>([]);
  const [eventos, setEventos] = useState<Evento[]>([]);
  const [atletaSelecionado, setAtletaSelecionado] = useState<number | null>(
    null
  );
  const [eventoSelecionado, setEventoSelecionado] = useState<string>("");

  const detalhesJogoSelecionado = jogosRodada.find(
    (jogo) => jogo.id === jogoSelecionado
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  const [eventoTemporario, setEventoTemporario] = useState<Evento | null>(null);

  const handleClose = () => {
    onClose();
    setEventoTemporario(null);
    setTimeSelecionado("");
    setAtletaSelecionado(null);
    setEventoSelecionado("");
  };

  const prepararAdicionarEvento = (
    idTime: "A" | "B",
    idAtleta: number,
    tipoEvento: "gol" | "amarelo" | "vermelho"
  ) => {
    const idAtletaNumerico = Number(idAtleta);
    const listaAtletas = idTime === "A" ? atletasTimeA : atletasTimeB;
    const atleta = listaAtletas.find(
      (atleta) => atleta.id === idAtletaNumerico
    );

    if (!atleta) {
      console.error("Atleta não encontrado.");
      return;
    }

    const nomeAtleta = atleta.nomeAtleta.split(" ")[0];

    setEventoTemporario({
      idTime,
      idAtleta: idAtletaNumerico,
      tipoEvento,
      nomeAtleta,
    });
    onOpen();
  };

  const removerEvento = (index: number) => {
    const eventoRemovido = eventos[index];

    if (eventoRemovido.tipoEvento === "gol") {
      const jogosAtualizados = jogosRodada.map((jogo) => {
        if (jogo.id === jogoSelecionado) {
          if (eventoRemovido.idTime === "A") {
            jogo.golsTimeA = Math.max(jogo.golsTimeA - 1, 0);
          } else {
            jogo.golsTimeB = Math.max(jogo.golsTimeB - 1, 0);
          }
        }
        return jogo;
      });
      setJogosRodada(jogosAtualizados);
    }

    const eventosAtualizados = eventos.filter((_, i) => i !== index);
    setEventos(eventosAtualizados);
  };

  const confirmarAdicaoEvento = () => {
    if (eventoTemporario) {
      adicionarEvento(
        eventoTemporario.idTime,
        eventoTemporario.idAtleta,
        eventoTemporario.tipoEvento,
        eventoTemporario.nomeAtleta
      );
      setEventoTemporario(null);
      setTimeSelecionado("");
      setAtletaSelecionado(null);
      setEventoSelecionado("");
    }
    onClose();
  };

  const adicionarEvento = (
    idTime: "A" | "B",
    idAtleta: number,
    tipoEvento: "gol" | "amarelo" | "vermelho",
    nomeAtleta: string
  ) => {
    const novoEvento: Evento = { idTime, idAtleta, tipoEvento, nomeAtleta };
    setEventos((prevEventos) => [...prevEventos, novoEvento]);

    setAtletaSelecionado(null);

    if (tipoEvento === "gol") {
      const jogosAtualizados = jogosRodada.map((jogo) => {
        if (jogo.id === jogoSelecionado) {
          if (idTime === "A") {
            jogo.golsTimeA += 1;
          } else {
            jogo.golsTimeB += 1;
          }
        }
        return jogo;
      });
      setJogosRodada(jogosAtualizados);
    }
  };

  const encerrarPartida = async () => {
    if (!detalhesJogoSelecionado) {
      console.error("Jogo selecionado não encontrado.");
      return;
    }

    try {
      const response = await httpEuro.get(`/euro/api/classificacao/2`);
      let classificacaoAtual = response.data.conteudo;

      const timeAId = Number(detalhesJogoSelecionado.idTimeA);
      const timeBId = Number(detalhesJogoSelecionado.idTimeB);

      const timeA = classificacaoAtual.find(
        (c: any) => c.classificacaoId.idTime === timeAId
      );
      const timeB = classificacaoAtual.find(
        (c: any) => c.classificacaoId.idTime === timeBId
      );

      if (!timeA || !timeB) {
        console.error("Um dos times não foi encontrado.");
        return;
      }

      const golsTimeA = detalhesJogoSelecionado.golsTimeA;
      const golsTimeB = detalhesJogoSelecionado.golsTimeB;

      timeA.qtdJogos += 1;
      timeA.golsPro += golsTimeA;
      timeA.golsContra += golsTimeB;

      timeB.qtdJogos += 1;
      timeB.golsPro += golsTimeB;
      timeB.golsContra += golsTimeA;

      if (golsTimeA > golsTimeB) {
        timeA.vitoria += 1;
        timeA.pontos += 3;
        timeB.derrota += 1;
      } else if (golsTimeA < golsTimeB) {
        timeB.vitoria += 1;
        timeB.pontos += 3;
        timeA.derrota += 1;
      } else {
        timeA.empate += 1;
        timeA.pontos += 1;
        timeB.empate += 1;
        timeB.pontos += 1;
      }

      const golsPorAtleta = eventos.reduce((acc, evento) => {
        if (evento.tipoEvento === "gol") {
          acc[evento.idAtleta] = (acc[evento.idAtleta] || 0) + 1;
        }
        return acc;
      }, {} as { [key: number]: number });

      const cartoesPorAtleta = eventos.reduce((acc, evento) => {
        if (
          evento.tipoEvento === "amarelo" ||
          evento.tipoEvento === "vermelho"
        ) {
          if (!acc[evento.idAtleta]) {
            acc[evento.idAtleta] = { amarelo: 0, vermelho: 0 };
          }
          acc[evento.idAtleta][evento.tipoEvento] += 1;
        }
        return acc;
      }, {} as { [key: number]: { amarelo: number; vermelho: number } });

      const bodyA = {
        classificacaoId: {
          idCampeonato: 2,
          idTime: timeAId,
        },
        pontos: timeA.pontos,
        qtdJogos: timeA.qtdJogos,
        vitoria: timeA.vitoria,
        empate: timeA.empate,
        derrota: timeA.derrota,
        golsPro: timeA.golsPro,
        golsContra: timeA.golsContra,
        grupo: "string",
        numeroFase: 0,
      };

      const bodyB = {
        classificacaoId: {
          idCampeonato: 2,
          idTime: timeBId,
        },
        pontos: timeB.pontos,
        qtdJogos: timeB.qtdJogos,
        vitoria: timeB.vitoria,
        empate: timeB.empate,
        derrota: timeB.derrota,
        golsPro: timeB.golsPro,
        golsContra: timeB.golsContra,
        grupo: "string",
        numeroFase: 0,
      };

      const jogoAtual = jogosRodada.find((jogo) => jogo.id === jogoSelecionado);
      if (!jogoAtual) {
        console.error("Jogo selecionado não encontrado.");
        return;
      }

      const bodyJogo = {
        id: jogoAtual.id,
        idTimeA: timeAId,
        idTimeB: timeBId,
        idCampeonato: 2,
        dataJogo: jogoAtual.dataJogo.split("/").reverse().join("-"),
        horaJogo: jogoAtual.horaJogo,
        golsTimeA: golsTimeA,
        golsTimeB: golsTimeB,
        juiz: "string",
        observacao: "string",
        rodada: rodadaSelecionada,
        situacaoJogo: "3",
      };

      // console.log("bodyJogo:", bodyJogo);

      // Chamadas para atualização da classificação e do jogo (descomentar para uso real)
      await Promise.all([
        httpEuro.put(`/euro/api/classificacao/altera`, bodyA),
        httpEuro.put(`/euro/api/classificacao/altera`, bodyB),     
        httpEuro.put(`/euro/api/jogos/altera`, bodyJogo),
      ]);

      const promessasDeGols = Object.entries(golsPorAtleta).map(
        ([idAtleta, quantidade]) => {
          return {
            idJogos: jogoAtual.id,
            idCamp: 2,
            idAtleta: parseInt(idAtleta, 10),
            quantidade: quantidade,
          };
        }
      );

      // console.log("Bodies para o serviço de gols:", promessasDeGols);

      // Prepara o corpo da requisição para o serviço de cartões
      const promessasDeCartoes = Object.entries(cartoesPorAtleta).map(
        ([idAtleta, { amarelo, vermelho }]) => {
          return {
            idAtleta: parseInt(idAtleta, 10),
            idCampeonato: 2,
            cartaoTipo: "1",
            idJogos: jogoAtual.id,
            quantidadeParcialAmarelo: amarelo,
            quantidadeParcialVermelho: vermelho,
          };
        }
      );

      // console.log("Bodies para o serviço de cartões:", promessasDeCartoes);

      promessasDeGols.forEach((bodyDeGol) =>
        httpEuro.post(`/euro/api/gols`, bodyDeGol)
      );
      promessasDeCartoes.forEach((bodyDeCartao) =>
        httpEuro.put(`/euro/api/cartao/altera`, bodyDeCartao)
      );

      // Espera pela conclusão de todas as promessas de gols e cartões
      await Promise.all([...promessasDeGols, ...promessasDeCartoes]);

      // Mensagem de sucesso (ajustar conforme necessário para sua UI)
      // console.log("Partida encerrada com sucesso.");
      alert("Partida encerrada com sucesso."); // Exemplo de uso de alerta para feedback ao usuário

      setJogoSelecionado(null); // Limpa o jogo selecionado
      setTimeSelecionado(""); // Limpa o time selecionado
      setAtletaSelecionado(null); // Limpa o atleta selecionado
      setEventoSelecionado(""); // Limpa o evento selecionado
      setEventos([]); // Limpa os eventos registrados
    } catch (error) {
      console.error("Erro ao encerrar a partida:", error);
    }
  };

  const renderizarIconeEvento = (tipoEvento: string) => {
    switch (tipoEvento) {
      case "gol":
        return <Icon as={FaFutbol} />;
      case "amarelo":
        return <Icon as={FaRegCircle} color="yellow.400" />;
      case "vermelho":
        return <Icon as={FaSquare} color="red.500" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    async function buscarAtletas() {
      if (jogoSelecionado) {
        const jogo = jogosRodada.find((j) => j.id === jogoSelecionado);
        if (jogo) {
          try {
            const [responseA, responseB] = await Promise.all([
              httpEuro.get(
                `/euro/api/atletas/listar-atletas-torneio-time/${jogo.idTimeA}`
              ),
              httpEuro.get(
                `/euro/api/atletas/listar-atletas-torneio-time/${jogo.idTimeB}`
              ),
            ]);

            setAtletasTimeA(responseA.data.conteudo);
            setAtletasTimeB(responseB.data.conteudo);
          } catch (error) {
            console.error("Erro ao buscar os atletas dos times:", error);
          }
        }
      }
    }

    buscarAtletas();
  }, [jogoSelecionado, jogosRodada]);

  useEffect(() => {
    const fetchJogosRodada = async () => {
      try {
        const response = await httpEuro.get(
          `/euro/api/jogos/listar-rodada/${rodadaSelecionada}`
        );
        // console.log("Jogos da rodada:", response.data.conteudo); // Log dos jogos da rodada

        // console.log("response.data.conteudo:", response.data.conteudo);
        setJogosRodada(response.data.conteudo || []);
      } catch (error) {
        console.error("Erro ao buscar os jogos da rodada:", error);
      }
    };

    if (rodadaSelecionada) {
      fetchJogosRodada();
    }
  }, [rodadaSelecionada]);

  const getColorFromTeamName = (teamName: string): string => {
    const lowerCaseTeamName = teamName.toLowerCase();
    if (lowerCaseTeamName.includes("amarelo")) {
      return "#FFFF00";
    } else if (lowerCaseTeamName.includes("verde")) {
      return "#008000";
    } else if (lowerCaseTeamName.includes("branco")) {
      return "#FFFFFF";
    } else if (lowerCaseTeamName.includes("vermelho")) {
      return "#FF0000";
    } else if (lowerCaseTeamName.includes("azul")) {
      return "#0000FF";
    } else {
      return "#000000";
    }
  };

  return (
    <ChakraProvider theme={theme}>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirmar Evento
            </AlertDialogHeader>

            <AlertDialogBody>
              Tem certeza que deseja adicionar este evento?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button colorScheme="blue" onClick={confirmarAdicaoEvento} ml={3}>
                Confirmar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Box
        bg="white"
        width="100%"
        maxWidth={{ xl: "1200px" }}
        mx="auto"
        p={4}
        color="black"
      >
        <Flex direction="column" gap="4">
          <Select
            placeholder="Selecione a rodada"
            value={rodadaSelecionada}
            onChange={(e) => setRodadaSelecionada(e.target.value)}
          >
            {Array.from({ length: 6 }, (_, index) => (
              <option key={index + 1} value={index + 1}>
                {index === 5 ? "Final" : `Rodada ${index + 1}`}
              </option>
            ))}
          </Select>

          {jogosRodada.length > 0 && (
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                colorScheme="darkGreen"
              >
                Selecione um jogo
              </MenuButton>
              <MenuList>
                {jogosRodada.map((jogo) => (
                  <MenuItem
                    key={jogo.id}
                    onClick={() => setJogoSelecionado(jogo.id)}
                  >
                    <Flex align="center">
                      <Box
                        borderRadius="full"
                        bg={getColorFromTeamName(jogo.timeA.nomeTime)}
                        width="30px"
                        height="30px"
                        mr={2}
                        border="1px solid black"
                      />
                      <Text>
                        {jogo.timeA.nomeTime} X {jogo.timeB.nomeTime}
                      </Text>
                      <Box
                        borderRadius="full"
                        bg={getColorFromTeamName(jogo.timeB.nomeTime)}
                        width="30px"
                        height="30px"
                        ml={2}
                        border="1px solid black"
                      />
                    </Flex>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          )}
          {jogoSelecionado &&
            detalhesJogoSelecionado &&
            (detalhesJogoSelecionado.situacaoJogo.idSituacaoJogo === 1 ? (
              <Box
                bg="white"
                p={7}
                mb={4}
                borderRadius="md"
                boxShadow="md"
                textAlign="center"
              >
                {jogosRodada
                  .filter((jogo) => jogo.id === jogoSelecionado)
                  .map((jogo) => (
                    <React.Fragment key={jogo.id}>
                      <Heading size={["md"]} mb="5">
                        {jogo.dataJogo} - {jogo.horaJogo}hs
                      </Heading>
                      <Flex align="center" justifyContent="center">
                        <Box
                          borderRadius="full"
                          bg={getColorFromTeamName(jogo.timeA.nomeTime)}
                          width="60px"
                          height="60px"
                          mr={4}
                          border="2px solid black"
                        />
                        <Heading size={["xl", "2xl"]} mx={2}>
                          {jogo.golsTimeA} x {jogo.golsTimeB}
                        </Heading>
                        <Box
                          borderRadius="full"
                          bg={getColorFromTeamName(jogo.timeB.nomeTime)}
                          width="60px"
                          height="60px"
                          ml={4}
                          border="2px solid black"
                        />
                      </Flex>
                    </React.Fragment>
                  ))}
                {detalhesJogoSelecionado && (
                  <Box
                    bg="white"
                    p={7}
                    mb={4}
                    borderRadius="md"
                    boxShadow="md"
                    textAlign="center"
                  >
                    <Box>
                      <Select
                        placeholder="Selecione o Time"
                        value={timeSelecionado}
                        onChange={(e) => setTimeSelecionado(e.target.value)}
                      >
                        <option value="A">
                          {detalhesJogoSelecionado.timeA.nomeTime}
                        </option>
                        <option value="B">
                          {detalhesJogoSelecionado.timeB.nomeTime}
                        </option>
                      </Select>

                      <Select
                        key={`select-atleta-${timeSelecionado}`}
                        placeholder="Selecione o Atleta"
                        value={
                          atletaSelecionado !== null
                            ? atletaSelecionado.toString()
                            : ""
                        }
                        onChange={(e) =>
                          setAtletaSelecionado(
                            e.target.value !== ""
                              ? parseInt(e.target.value, 10)
                              : null
                          )
                        }
                      >
                        {(timeSelecionado === "A"
                          ? atletasTimeA
                          : atletasTimeB
                        ).map((atleta) => (
                          <option key={atleta.id} value={atleta.id.toString()}>
                            {atleta.nomeAtleta}
                          </option>
                        ))}
                      </Select>

                      <Select
                        placeholder="Selecione o Evento"
                        value={eventoSelecionado}
                        onChange={(e) => setEventoSelecionado(e.target.value)}
                      >
                        <option value="gol">Gol</option>
                        <option value="amarelo">Cartão Amarelo</option>
                        <option value="vermelho">Cartão Vermelho</option>
                      </Select>
                    </Box>
                  </Box>
                )}

                <Flex direction="column">
                  {eventos.map((evento, index) => (
                    <Flex
                      key={index}
                      justify={
                        evento.idTime === "A" ? "flex-start" : "flex-end"
                      }
                    >
                      {renderizarIconeEvento(evento.tipoEvento)}
                      <Text ml={2}>
                        {evento.nomeAtleta} - {evento.tipoEvento}
                      </Text>
                    </Flex>
                  ))}
                </Flex>

                <Box
                  bg="white"
                  p={7}
                  mb={4}
                  borderRadius="md"
                  boxShadow="md"
                  textAlign="center"
                >
                  <Button
                    colorScheme="darkGreen"
                    onClick={() => {
                      if (timeSelecionado === "A" || timeSelecionado === "B") {
                        if (atletaSelecionado !== null) {
                          if (
                            eventoSelecionado === "gol" ||
                            eventoSelecionado === "amarelo" ||
                            eventoSelecionado === "vermelho"
                          ) {
                            prepararAdicionarEvento(
                              timeSelecionado,
                              atletaSelecionado,
                              eventoSelecionado
                            );
                          } else {
                            console.error("Selecione um evento válido.");
                          }
                        } else {
                          console.error(
                            "Selecione um atleta antes de adicionar o evento."
                          );
                        }
                      } else {
                        console.error(
                          "Selecione um time válido antes de adicionar o evento."
                        );
                      }
                    }}
                  >
                    Adicionar Evento
                  </Button>

                  <Button
                    marginTop={1}
                    marginLeft={1}
                    colorScheme="red"
                    onClick={encerrarPartida}
                  >
                    Encerrar Partida
                  </Button>
                </Box>

                <Flex direction="column">
                  {eventos.map((evento, index) => (
                    <Flex
                      key={index}
                      justify={
                        evento.idTime === "A" ? "flex-start" : "flex-end"
                      }
                      align="center"
                      marginBottom={2}
                    >
                      {renderizarIconeEvento(evento.tipoEvento)}
                      <Text ml={2}>
                        {evento.nomeAtleta} - {evento.tipoEvento}
                      </Text>
                      <Button
                        ml="4"
                        size="sm"
                        colorScheme="red"
                        onClick={() => removerEvento(index)}
                      >
                        Remover
                      </Button>
                    </Flex>
                  ))}
                </Flex>
              </Box>
            ) : (
              <Text textAlign="center" mt={4} fontSize="lg" fontWeight="bold">
                Jogo não permitido para inserir eventos.
              </Text>
            ))}

          {/* Implemente aqui a UI para seleção de jogadores e eventos */}
        </Flex>
      </Box>
    </ChakraProvider>
  );
}

export default Mesa;
